import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import Grid from "@material-ui/core/Grid"
import BoatCard from "../../components/boat-card/boat-card.component"

const Models = () => {
  const data = useStaticQuery(graphql`
    query showBoats {
      allWpBoat(sort: { fields: date, order: DESC }) {
        edges {
          node {
            boatPostTypeFields {
              generalInfo {
                name
                heroBackground {
                  sourceUrl
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                    }
                  }
                  altText
                  title
                }
                cardImage {
                  sourceUrl
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                    }
                  }
                  altText
                  title
                }
                specifications {
                  name
                  value
                }
              }
            }
            slug
          }
        }
      }
    }
  `)

  const postData = data.allWpBoat.edges

  const dataQuantity = 3
  let innerData = []

  return (
    <SectionWrapper bg="primary" contained>
      <Grid container spacing={2}>
        {postData.map(({ node }, index) => {
          let nodeName, nodeImage, cardImage, nodeUrl, values

          nodeName = node.boatPostTypeFields.generalInfo?.name
          cardImage = node.boatPostTypeFields.generalInfo?.cardImage
          nodeImage = node.boatPostTypeFields.generalInfo?.heroBackground
          nodeUrl = node.slug
          values = node.boatPostTypeFields.generalInfo?.specifications.slice(
            0,
            dataQuantity
          )

          innerData = [
            {
              name: values[0].name,
              value: values[0].value,
            },
            {
              name: values[1].name,
              value: values[1].value,
            },
            {
              name: values[2].name,
              value: values[2].value,
            },
          ]

          return (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <BoatCard
                name={nodeName}
                url={nodeUrl}
                img={cardImage ?? nodeImage}
                details={innerData}
                showDetails
              />
            </Grid>
          )
        })}
      </Grid>
    </SectionWrapper>
  )
}

export default Models
